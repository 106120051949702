import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webMessages: [],
  isLoading: false,
  isSkeleton: false,
};
export const getWebMessages = createAsyncThunk(
  "webMessages/getWebMessages",
  async (payload) => {
    return apiInstance.get(
      `webMessages/getWebMessages?webMessagesStauts=${payload}`
    );
  }
);
export const createWebMessages = createAsyncThunk(
  "webMessages/createWebMessages",
  async (payload) => {
    return apiInstance.post("webMessages/createWebMessages", payload);
  }
);
export const updateWebMessages = createAsyncThunk(
  "webMessages/updateWebMessages",
  async (payload) => {
    return apiInstance.patch(
      `webMessages/updateWebMessages?webMessagesId=${payload.webMessagesId}`,
      payload.addWebMessages
    );
  }
);
export const updateWebMessagesStatus = createAsyncThunk(
  "webMessages/updateWebMessagesStatus",
  async (payload) => {
    return apiInstance.put(
      `webMessages/updateWebMessagesStatus?webMessagesId=${payload}`
    );
  }
);
export const deleteWebMessages = createAsyncThunk(
  "webMessages/deleteWebMessages",
  async (webMessagesId) => {
    return apiInstance.delete(
      `webMessages/deleteWebMessages?webMessagesId=${webMessagesId}`
    );
  }
);

const webMessagesSlice = createSlice({
  name: "webMessagesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebMessages
    builder.addCase(getWebMessages.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getWebMessages.fulfilled, (state, action) => {
      state.webMessages = action.payload.webMessages;
      state.isSkeleton = false;
    });
    builder.addCase(getWebMessages.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webMessagesCreate
    builder.addCase(createWebMessages.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createWebMessages.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webMessages.unshift(action.payload.webMessages);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(createWebMessages.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebMessages
    builder.addCase(updateWebMessages.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebMessages.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webMessagesIdx = state.webMessages.findIndex(
          (webMessages) => webMessages._id === action.payload.webMessages._id
        );
        if (webMessagesIdx !== -1) {
          state.webMessages[webMessagesIdx] = {
            ...state.webMessages[webMessagesIdx],
            ...action.payload.webMessages,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebMessages.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebMessagesStatus
    builder.addCase(updateWebMessagesStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebMessagesStatus.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webMessagesIdx = state.webMessages.findIndex(
          (webMessages) => webMessages._id === action.payload.webMessages._id
        );
        if (webMessagesIdx !== -1) {
          state.webMessages[webMessagesIdx] = {
            ...state.webMessages[webMessagesIdx],
            ...action.payload.webMessages,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebMessagesStatus.rejected, (state, action) => {
      state.isLoading = false;
    });

    // deleteWebMessages
    builder.addCase(deleteWebMessages.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWebMessages.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webMessages = state.webMessages.filter(
          (webMessages) => webMessages._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(deleteWebMessages.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webMessagesSlice.reducer;
