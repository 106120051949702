import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../extra/Swal/Alert";
import WebMessagesAdd from "./WebMessagesAdd";
import {
  deleteWebMessages,
  getWebMessages,
  updateWebMessagesStatus,
} from "../../../../redux/slice/webMessagesSlice";
import ToggleSwitch from "../../../extra/Buttons/ToggleSwitch";
const WebMessages = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { webMessages } = useSelector((state) => state.webMessages);

  // Pagination BOTH

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [stuatsShowData, setStatusShowData] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getWebMessages(stuatsShowData));
  }, [page, stuatsShowData]);

  useEffect(() => {
    setData(webMessages);
  }, [webMessages]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteWebMessages(id));
        }
      })
      .catch((err) => console.log(err));
  };
  const handleMessageRead = (statusData) => {
    let statusArgData;
    statusData.status
      ? (statusArgData = [
          "Resolve Message?",
          "",
          "Resolve",
          "ri-shield-check-line",
          "blue",
        ])
      : (statusArgData = [
          "Solve This Message?",
          "",
          "Solve",
          "ri-shield-check-line",
          "success",
        ]);
    const data = warning(...statusArgData);
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(updateWebMessagesStatus(statusData._id));
        }
      })
      .catch((err) => console.log(err));
  };
  const webMessagesTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "First Name", body: "firstName", sorting: { type: "client" } },
    { Header: "Last Name", body: "lastName", sorting: { type: "client" } },
    { Header: "Email", body: "email", sorting: { type: "client" } },
    { Header: "Messages", body: "message", sorting: { type: "client" } },
    {
      Header: "Read",
      body: "status",
      Cell: ({ row }) => (
        <span>
          <ToggleSwitch
            onClick={() => handleMessageRead(row)}
            value={row?.status}
          />
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray border-solid-darkGray-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "webMessages",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-danger-light text-danger border-solid-danger-1 m-5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12 d-flex">
          <Title name={"Web Message"} icon={`ri-chat-3-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div>
            <Button
              className={`${
                !stuatsShowData
                  ? "bg-blue text-light"
                  : "bg-blue-light text-blue "
              } border-solid-blue-2 m-20-right`}
              text={`Pending Messages`}
              onClick={() => {
                setStatusShowData(false);
              }}
            />
            <Button
              className={`${
                stuatsShowData
                  ? "bg-success text-light"
                  : "bg-success-light text-success "
              } border-solid-success-2 m-20-right`}
              text={`Solve Messages`}
              onClick={() => {
                setStatusShowData(true);
              }}
            />
          </div>
          <div className="m-10-right">
            <Searching
              type={`client`}
              data={webMessages}
              setData={setData}
              column={webMessagesTable}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-solid-darkGray-2 m-20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "webMessages" }));
              }}
            />
          </div>
        </div>
      </div>

      {/* Client */}
      <Table
        data={webMessages}
        mapData={webMessagesTable}
        Page={page}
        PerPage={rowsPerPage}
      />

      {/* Client */}
      <Pagination
        type={"client"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={data?.length}
      />

      {dialogue && dialogueType === "webMessages" && <WebMessagesAdd />}
    </>
  );
};

export default WebMessages;
