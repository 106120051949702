import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  editData,
  submitData,
  Textarea,
} from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import Button from "../../../extra/Buttons/Button";
import { setToast } from "../../../extra/toast";
import { warning } from "../../../extra/Swal/Alert";
import { baseURL } from "../../../util/config";
import {
  createWebContact,
  updateWebContact,
} from "../../../../redux/slice/webContactSlice";

const WebContactAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const [infoId, setInfoId] = useState("");
  const [infoData, setInfoData] = useState([]);
  const [info, setInfo] = useState({
    text: "",
    link: "",
  });

  const [contactData, setContactData] = useState(1);
  const [showIcon, setShowIcon] = useState("");

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webContactForm");
      setInfoData(dialogueData.info);
      setShowIcon(dialogueData.icon);
      setContactData(dialogueData.isDairect);
    }
  }, [dialogueData]);
  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webContactForm");
    }
  }, [contactData]);

  const handleSubmit = async (e) => {
    const addWebContact = submitData(e);

    if (addWebContact) {
      const addSomeContact = {
        ...addWebContact,
        info: infoData,
      };

      console.log("addSomeContact", addSomeContact);
      try {
        let response;
        if (dialogueData) {
          const payload = {
            addSomeContact,
            webContactId: dialogueData._id,
          };
          response = await dispatch(updateWebContact(payload)).unwrap();
        } else {
          response = await dispatch(createWebContact(addSomeContact)).unwrap();
        }
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webContactForm`}
      title={`Contact Dialogue`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-8 col-xl-9 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-6 align-self-center  m-20-bottom">
          <Input
            type={`radio`}
            id={`isDairect1`}
            name={`isDairect`}
            label={`Info Details`}
            value={1}
            onChange={(e) => setContactData(parseInt(e.target.value))}
            checked={contactData == 1 && true}
          />
        </div>
        <div className="col-6 align-self-center m-20-bottom">
          <Input
            type={`radio`}
            id={`isDairect2`}
            name={`isDairect`}
            label={`Icon Details`}
            value={2}
            onChange={(e) => setContactData(parseInt(e.target.value))}
            checked={contactData == 2 && true}
          />
        </div>
        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Contact Icon Details
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row">
              <div className="col-sm-6 col-12">
                <Input
                  type={`text`}
                  id={`title`}
                  name={`title`}
                  label={`Title`}
                  errorMessage={`Enter Title`}
                />
                {contactData == 2 && (
                  <Input
                    type={`text`}
                    id={`dairectLink`}
                    name={`dairectLink`}
                    label={`Dairect Link`}
                    errorMessage={`Enter Dairect Link`}
                  />
                )}
              </div>
              <div className="col-sm-6 col-12">
                <Input
                  type={`text`}
                  id={`icon`}
                  name={`icon`}
                  label={`Icon`}
                  errorMessage={`Enter Icon`}
                  onChange={(e) => setShowIcon(e.target.value)}
                />
                {showIcon && (
                  <div className="showIconData hw-70 d-flex justify-content-center align-items-center border-solid-second-2 border-round-50 text-second  m-25-bottom">
                    <i className={`${showIcon} fs-28`}></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {contactData == 1 && (
          <div className="col-12 m-20-bottom">
            <div className="text-darkGray fs-14 m-5-bottom fw-600">
              Contact Info Details
            </div>
            <div className="border-dashed-second-1 p-20 border-radius-10">
              <div className="row align-items-end">
                <div className="col-8">
                  <Input
                    type={`text`}
                    id={`text`}
                    name={`text`}
                    label={`Text`}
                    value={info.text}
                    onChange={(e) =>
                      setInfo({ ...info, [e.target.name]: e.target.value })
                    }
                    ignore={true}
                  />
                </div>
                <div className="col-12">
                  <Textarea
                    type={`text`}
                    id={`link`}
                    name={`link`}
                    label={`Link`}
                    value={info.link}
                    onChange={(e) =>
                      setInfo({ ...info, [e.target.name]: e.target.value })
                    }
                    ignore={true}
                    row={2}
                  />
                </div>
                <div className="col-2">
                  <Button
                    className={`bg-gray2 text-darkGray border-solid-darkGray-2`}
                    text={`Add`}
                    type={`button`}
                    onClick={() => {
                      if (info.link) {
                        if (infoId >= 0 && infoId !== "") {
                          setInfoData(
                            infoData.map((editVal, editI) =>
                              editI == infoId ? info : editVal
                            )
                          );
                        } else {
                          setInfoData([...infoData, info]);
                        }
                        setInfo({ text: "", link: "" });
                        setInfoId("");
                      } else {
                        setToast("error", "Enter Info Description");
                      }
                    }}
                  />
                </div>
                <div className="col-12 m-20-top">
                  <div className="text-darkGray fs-14 m-5-bottom fw-600">
                    What You'll Info
                  </div>
                  <div
                    className="bg-light p-10-y p-20-x border-radius-10"
                    style={{ minHeight: "100px" }}
                  >
                    <ul className="mainInfo fs-14">
                      {infoData?.map((res, i) => (
                        <li
                          className={`d-flex align-items-center ${
                            infoId === i ? "text-gray" : "text-darkGray"
                          }`}
                        >
                          <div className="fs-18 m-8-right">
                            <i
                              class="ri-edit-2-line"
                              onClick={() => {
                                setInfo({
                                  text: res.text,
                                  link: res.link,
                                });
                                setInfoId(i);
                              }}
                            ></i>
                            <i
                              class="ri-delete-bin-line"
                              onClick={() => {
                                const data = warning();
                                data
                                  .then((logouts) => {
                                    const yes = logouts.isConfirmed;
                                    console.log("yes", yes);
                                    if (yes) {
                                      setInfoData(
                                        infoData.filter(
                                          (_, editI) => editI !== i
                                        )
                                      );
                                    }
                                  })
                                  .catch((err) => console.log(err));
                              }}
                            ></i>
                          </div>
                          {res.text != "" ? (
                            <div>
                              <b>{res.text}</b> : {res.link}
                            </div>
                          ) : (
                            <div className="m-15-top">{res.link}</div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <Input
                    type={`text`}
                    id={`notice`}
                    name={`notice`}
                    label={`Notice`}
                    errorMessage={`Enter Notice`}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </DialogBox>
  );
};

export default WebContactAdd;
