import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  Textarea,
  editData,
  submitData,
} from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import {
  createWebMessages,
  updateWebMessages,
} from "../../../../redux/slice/webMessagesSlice";
const WebMessagesAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webMessagesForm");
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addWebMessages = submitData(e);
    if (addWebMessages) {
      try {
        let response;
        if (dialogueData) {
          const payload = { addWebMessages, webMessagesId: dialogueData._id };
          response = await dispatch(updateWebMessages(payload)).unwrap();
        } else {
          response = await dispatch(createWebMessages(addWebMessages)).unwrap();
        }
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webMessagesForm`}
      title={`Web Messages Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-6 col-lg-8 col-md-9 col-11`}
    >
      <div className="row align-items-center formBody">
        <div className="col-6">
          <Input
            type={`text`}
            id={`firstName`}
            name={`firstName`}
            label={`First Name`}
            errorMessage={`Enter First Name`}
          />
        </div>
        <div className="col-6">
          <Input
            type={`text`}
            id={`lastName`}
            name={`lastName`}
            label={`Last Name`}
            errorMessage={`Enter Last Name`}
          />
        </div>
        <div className="col-12">
          <Input
            type={`text`}
            id={`email`}
            name={`email`}
            label={`Email`}
            errorMessage={`Enter Email`}
          />
        </div>
        <div className="col-12">
          <Textarea
            row={3}
            id={`message`}
            name={`message`}
            label={`Messages`}
            errorMessage={`Enter Messages`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default WebMessagesAdd;
