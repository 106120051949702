import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webContactFullData: [],
  webContactIconData: [],
  isLoading: false,
  isSkeleton: false,
};
export const getWebContact = createAsyncThunk(
  "webContact/getWebContact",
  async (payload) => {
    return apiInstance.get(`webContact/getWebContact`);
  }
);
export const createWebContact = createAsyncThunk(
  "webContact/createWebContact",
  async (payload) => {
    return apiInstance.post("webContact/createWebContact", payload);
  }
);
export const updateWebContact = createAsyncThunk(
  "webContact/updateWebContact",
  async (payload) => {
    return apiInstance.patch(
      `webContact/updateWebContact?webContactId=${payload.webContactId}`,
      payload.addSomeContact
    );
  }
);
export const deleteWebContact = createAsyncThunk(
  "webContact/deleteWebContact",
  async (payload) => {
    return apiInstance.delete(
      `webContact/deleteWebContact?webContactId=${payload.id}`
    );
  }
);

const webContactSlice = createSlice({
  name: "webContactSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebContact
    builder.addCase(getWebContact.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getWebContact.fulfilled, (state, action) => {
      state.webContactFullData = action.payload.webContactFullData;
      state.webContactIconData = action.payload.webContactIconData;
      state.isSkeleton = false;
    });
    builder.addCase(getWebContact.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webContactCreate
    builder.addCase(createWebContact.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createWebContact.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        if (action.payload.webContact.isDairect == 1) {
          state.webContactFullData.push(action.payload.webContact);
        }
        if (action.payload.webContact.isDairect == 2) {
          state.webContactIconData.push(action.payload.webContact);
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(createWebContact.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebContact
    builder.addCase(updateWebContact.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebContact.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webStatus =
          action.payload.webContact.isDairect == 1
            ? "webContactFullData"
            : "webContactIconData";
        const webContactIdx = state[webStatus].findIndex(
          (webContact) => webContact._id === action.payload.webContact._id
        );
        if (webContactIdx !== -1) {
          state[webStatus][webContactIdx] = {
            ...state[webStatus][webContactIdx],
            ...action.payload.webContact,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebContact.rejected, (state, action) => {
      state.isLoading = false;
    });

    // deleteWebContact
    builder.addCase(deleteWebContact.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWebContact.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webStatus =
          action.meta.arg.isDairect == 1
            ? "webContactFullData"
            : "webContactIconData";
        state[webStatus] = state[webStatus].filter(
          (webContact) => webContact._id !== action.meta.arg.id
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(deleteWebContact.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webContactSlice.reducer;
