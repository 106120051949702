import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  editData,
  Select,
  submitData,
} from "../../../extra/Inputs/Input";
import { generateNum } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  inquiryAdd,
  inquiryUpdate,
} from "../../../../redux/slice/inquirySlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";

const InquiryAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "inquireForm");
    }
  }, [dialogueData]);

  const { course } = useSelector((state) => state.course);

  const options = course.map((res) => res.courseName);

  const handleSubmit = async (e) => {
    const addInquiry = submitData(e);
    console.log("addInquiry", addInquiry);
    if (addInquiry) {
      try {
        let response;
        if (dialogueData) {
          const payload = { addInquiry, inquiryId: dialogueData._id };
          response = await dispatch(inquiryUpdate(payload)).unwrap();
        } else {
          response = await dispatch(inquiryAdd(addInquiry)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <DialogBox
      id={`inquireForm`}
      title={`Inquiry Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xl-5 col-md-8 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-lg-6 col-12">
          <Input
            type={`text`}
            id={`name`}
            name={`name`}
            label={`Name`}
            errorMessage={`Enter Name`}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`number`}
            id={`inquiryId`}
            name={`inquiryId`}
            label={`Form Code`}
            errorMessage={`Enter Form Code`}
            validation={`^[a-zA-Z0-9]{6,6}$`}
            validationError={`Invalid Form Code`}
            activeIcon={!dialogueData && `ri-information-line`}
            activClick={() => generateNum(6, "inquiryId")}
            readOnly
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`number`}
            id={`mobileNo`}
            name={`mobileNo`}
            label={`Mobile Number`}
            errorMessage={`Enter Mobile Number`}
            validation={`^[0-9]{10,10}$`}
            validationError={`Invalid Mobile Number`}
          />
        </div>
        <div className="col-lg-6 col-12">
          {/* <Input
            type={`text`}
            id={`course`}
            name={`course`}
            label={`Course`}
            errorMessage={`Enter Course`}
          /> */}
          <Select
            id={`course`}
            name={`course`}
            label={`Course`}
            option={options}
            errorMessage={`Enter Course`}
          />
        </div>
        <div className="col-lg-6 col-12 inputData">
          <label className="m-20-bottom"> Gender :- </label>
          <div className="row">
            <div className="col-6 align-self-center">
              <Input
                type={`radio`}
                id={`male`}
                name={`gender`}
                label={`Male`}
                value={`male`}
              />
            </div>
            <div className="col-6 align-self-center">
              <Input
                type={`radio`}
                id={`female`}
                name={`gender`}
                label={`Female`}
                value={`female`}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12 inputData">
          <label className="m-20-bottom"> Type :- </label>
          {dialogueData ? (
            <div className="row">
              <div className="col-6 align-self-center">
                <Input
                  type={`radio`}
                  id={`custom`}
                  name={`type`}
                  label={`Custom`}
                  value={1}
                />
              </div>
              <div className="col-6 align-self-center">
                <Input
                  type={`radio`}
                  id={`web`}
                  name={`type`}
                  label={`Web`}
                  value={2}
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-6 align-self-center">
                <Input
                  type={`radio`}
                  id={`custom`}
                  name={`type`}
                  label={`Custom`}
                  value={1}
                  checked={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </DialogBox>
  );
};

export default InquiryAdd;
