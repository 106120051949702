import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../extra/Swal/Alert";
// import WebContactAdd from "./WebContactAdd";
import {
  deleteWebContact,
  getWebContact,
} from "../../../../redux/slice/webContactSlice";
import { Link } from "react-router-dom";
import WebContactAdd from "./WebContactAdd";
const WebContact = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [infoData, setInfoData] = useState([]);
  const [iconData, setIconData] = useState([]);

  const { webContactFullData, webContactIconData } = useSelector(
    (state) => state.webContact
  );

  useEffect(() => {
    dispatch(getWebContact());
  }, []);

  useEffect(() => {
    setInfoData(webContactFullData);
    setIconData(webContactIconData);
  }, [webContactFullData, webContactIconData]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteWebContact(id));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Web Contact"} icon={`ri-contacts-book-2-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end justify-content-center">
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-solid-darkGray-2 m-20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "webContact" }));
              }}
            />
          </div>
        </div>
        <div className="showContactData m-50-top text-center">
          <h2 className="text-start m-30-bottom fs-24">Contact Info Details</h2>
          <div className="row justify-content-md-start justify-content-center">
            {infoData.map((res) => (
              <div className="col-xxl-4 col-md-6 col-sm-8 col-12 m-30-bottom">
                <div className="contactDataLayer bg-light p-20 border-radius-10 border-solid-second-1">
                  <div className="contactIcon hw-70 d-flex justify-content-center align-items-center border-solid-second-2 border-round-50 text-second m-auto  m-25-bottom">
                    <i className={`${res.icon} fs-24`}></i>
                  </div>
                  <div className="contactTitle m-15-bottom fw-600">
                    {res.title}
                  </div>
                  {res.info.map((infoShow) => (
                    <div className="contactInfo">
                      <div className="contactText m-5-bottom fs-16">
                        {infoShow.text}
                      </div>
                      <div className="contactLink m-25-bottom">
                        <Link to={infoShow.link} target="_black">
                          <Button
                            text={`Click To Show`}
                            className={`bg-second-light text-second border-solid-second-2 fs-lg-12 fs-sm-10 fs-10`}
                          />
                        </Link>
                      </div>
                    </div>
                  ))}
                  <div className="contactNotice m-25-bottom fs-16 text-second">
                    {res.notice && `(${res.notice})`}
                  </div>
                  <div className="actionContactBtn">
                    <ActionButton
                      className="bg-darkGray-light text-darkGray  border-solid-darkGray-2 m-10-right fs-16"
                      icon={`ri-pencil-line`}
                      onClick={() => {
                        dispatch(openDialog({ type: "webContact", data: res }));
                      }}
                    />
                    <ActionButton
                      className="bg-danger-light text-danger border-solid-danger-2 fs-16"
                      icon={`ri-delete-bin-6-line`}
                      onClick={() =>
                        handleDelete({ id: res._id, isDairect: res.isDairect })
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="showContactData m-50-top text-center">
          <h2 className="text-start m-30-bottom fs-24">Contact Icon Details</h2>
          <div className="row justify-content-md-start justify-content-center">
            {iconData.map((res) => (
              <div className="col-xxl-4 col-md-6 col-sm-8 col-12 m-30-bottom">
                <div className="contactDataLayer bg-light p-20 border-radius-10 border-solid-second-1">
                  <div className="contactIcon hw-70 d-flex justify-content-center align-items-center border-solid-second-2 border-round-50 text-second m-auto  m-25-bottom">
                    <i className={`${res.icon} fs-24`}></i>
                  </div>
                  <div className="contactTitle m-15-bottom fw-600">
                    {res.title}
                  </div>
                  <Link to={res.dairectLink} target="_black">
                    <Button
                      text={`Click To Show`}
                      className={`bg-second-light text-second border-solid-second-2 fs-lg-12 fs-sm-10 fs-10`}
                    />
                  </Link>
                  {res.info.map((infoShow) => (
                    <div className="contactInfo">
                      <div className="contactText m-5-bottom fs-16">
                        {infoShow.text}
                      </div>
                      <div className="contactLink m-25-bottom"></div>
                    </div>
                  ))}
                  <div className="contactNotice m-25-bottom fs-16 text-second">
                    {res.notice && `(${res.notice})`}
                  </div>
                  <div className="actionContactBtn">
                    <ActionButton
                      className="bg-darkGray-light text-darkGray  border-solid-darkGray-2 m-10-right fs-16"
                      icon={`ri-pencil-line`}
                      onClick={() => {
                        dispatch(openDialog({ type: "webContact", data: res }));
                      }}
                    />
                    <ActionButton
                      className="bg-danger-light text-danger border-solid-danger-2 fs-16"
                      icon={`ri-delete-bin-6-line`}
                      onClick={() =>
                        handleDelete({ id: res._id, isDairect: res.isDairect })
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {dialogue && dialogueType === "webContact" && <WebContactAdd />}
    </>
  );
};

export default WebContact;
