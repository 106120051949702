import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  Image,
  Textarea,
  editData,
  objectToFormData,
  submitData,
} from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  webReviewsAdd,
  webReviewsUpdate,
} from "../../../../redux/slice/webReviewsSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";

const WebReviewsAdd = ({ reviewType }) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webReviewsForm");
    }
  }, [dialogueData]);

  const { webReviews } = useSelector((state) => state.webReviews);
  console.log("webReviews", webReviews);

  const handleSubmit = async (e) => {
    let addReviews = submitData(e);
    // addReviews = { ...addReviews, type: reviewType };
    debugger
    if (addReviews) {
      console.log("addReviews===============", addReviews);
      const formData = objectToFormData(addReviews);

      try {
        let response;
        if (dialogueData) {
          const payload = { formData, webReviewsId: dialogueData._id };
          response = await dispatch(webReviewsUpdate(payload)).unwrap();
        } else {
          response = await dispatch(webReviewsAdd(formData)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const typeName = reviewType == 1 ? "Client" : "Student";

  return (
    <DialogBox
      id={`webReviewsForm`}
      title={`${typeName} Reviews Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-6 col-lg-8 col-md-9 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-lg-6 col-12">
          <Input
            type={`text`}
            id={`name`}
            name={`name`}
            label={`${typeName} Name`}
            errorMessage={`Enter Name`}
          />
        </div>
        <div className="col-3 align-self-center">
          <Input
            type={`radio`}
            id={`client`}
            name={`type`}
            label={`Client`}
            value={1}
            checked={reviewType == 1 && true}
          />
        </div>
        <div className="col-3 align-self-center">
          <Input
            type={`radio`}
            id={`student`}
            name={`type`}
            label={`Student`}
            value={2}
            checked={reviewType == 2 && true}
          />
        </div>
        <div className="col-12">
          <Textarea
            row={3}
            id={`reviews`}
            name={`reviews`}
            label={`${typeName} Reviews`}
            errorMessage={`Enter Reviews`}
          />
        </div>
        <div className="col-12">
          <Image
            type={`file`}
            id={`image`}
            name={`image`}
            label={`${typeName} Image`}
            errorMessage={`Enter Image`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default WebReviewsAdd;
