import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../extra/Swal/Alert";
import ToggleSwitch from "../../../extra/Buttons/ToggleSwitch";
import { baseURL } from "../../../util/config";
import {
  deleteWebCourse,
  getWebCourse,
  updateWebShowCourse,
} from "../../../../redux/slice/webCourseSlice";
import WebCourseAdd from "./WebCoursesAdd";
import { getWebCourseCategory } from "../../../../redux/slice/webCourseCategorySlice";
import { getWebCourseOpportunities } from "../../../../redux/slice/webCourseOpportunitiesSlice";
import { webTaskGet } from "../../../../redux/slice/webTaskSlice";
import WebCoursesShow from "./WebCoursesShow";
// import WebTaskAdd from "./WebTaskAdd";
const WebCourses = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { webCourse } = useSelector((state) => state.webCourse);

  useEffect(() => {
    dispatch(getWebCourseCategory());
    dispatch(getWebCourseOpportunities());
    dispatch(webTaskGet());
  }, []);

  // Pagination BOTH
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    dispatch(getWebCourse());
  }, []);

  useEffect(() => {
    setData(webCourse);
  }, [webCourse]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteWebCourse(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleShowCourse = (id) => {
    dispatch(updateWebShowCourse(id));
  };

  const webTaskTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
      tdClass: "width-50",
    },

    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <span>
          <img src={baseURL + row.image} className="width-200" alt="" />
        </span>
      ),
      tdClass: "width-200",
    },
    {
      Header: "Icon",
      body: "icon",
      Cell: ({ row }) => (
        <span>
          <div className="courseIcon m-auto hw-sm-60 hw-50 bg-second p-md-15 p-12 border-round-50">
            <img
              src={baseURL + row?.icon}
              alt="course"
              style={{ objectFit: "contain" }}
            />
          </div>
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "width-200",
    },
    {
      Header: "Course Name",
      body: "name",
      sorting: { type: "client" },
      tdClass: "width-200",
    },
    {
      Header: "Head Name",
      body: "headTitle",
      sorting: { type: "client" },
      tdClass: "width-200",
    },
    {
      Header: "Course Category",
      body: "courseCategory.categoryName",
      Cell: ({ row }) => <div>{row.courseCategory?.categoryName}</div>,
    },
    {
      Header: "Duration",
      body: "duration",
      sorting: { type: "client" },
      tdClass: "width-200",
    },
    {
      Header: "Hours",
      body: "hours",
      sorting: { type: "client" },
      tdClass: "width-200",
    },
    {
      Header: "Home Page",
      body: "courseCategory.categoryName",
      Cell: ({ row }) => (
        <div>
          <ToggleSwitch
            onClick={() => handleShowCourse(row._id)}
            value={row?.isShow}
          />
        </div>
      ),
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray border-solid-darkGray-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "webCourse",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-danger-light text-danger border-solid-danger-1 m-5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
          <ActionButton
            className="bg-blue-light text-blue border-solid-blue-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "webCourseShow",
                  data: row.courseLink,
                })
              )
            }
            icon={`ri-information-line`}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Web Courses"} icon={`ph ph-student`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m-10-right">
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={webTaskTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-solid-darkGray-2 m-20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "webCourse" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={webCourse}
        mapData={webTaskTable}
        Page={page}
        serverPerPage={rowsPerPage}
      />

      <Pagination
        type={"server"}
        onPageChange={handleChangePage}
        serverPerPage={rowsPerPage}
        totalData={data?.length}
        serverPage={page}
        setServerPage={setPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {dialogue && dialogueType === "webCourse" && <WebCourseAdd />}
      {dialogue && dialogueType === "webCourseShow" && <WebCoursesShow />}
    </>
  );
};

export default WebCourses;
