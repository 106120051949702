import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webReviews: [],
  isLoading: false,
  isSkeleton: false,
};
export const webReviewsGet = createAsyncThunk(
  "webReviews/webReviewsGet",
  async (payload) => {
    return apiInstance.get(
      `webReviews/webReviewsGet?webReviewsType=${payload}`
    );
  }
);
export const webReviewsAdd = createAsyncThunk(
  "webReviews/webReviewsAdd",
  async (payload) => {
    return apiInstance.post("webReviews/webReviewsAdd", payload);
  }
);
export const webReviewsUpdate = createAsyncThunk(
  "webReviews/webReviewsUpdate",
  async (payload) => {
    return apiInstance.patch(
      `webReviews/webReviewsUpdate?webReviewsId=${payload.webReviewsId}`,
      payload.formData
    );
  }
);
export const webReviewsDelete = createAsyncThunk(
  "webReviews/webReviewsDelete",
  async (webReviewsId) => {
    return apiInstance.delete(
      `webReviews/webReviewsDelete?webReviewsId=${webReviewsId}`
    );
  }
);

export const webReviewsStatusUpdate = createAsyncThunk(
  "webReviews/webReviewsStatusUpdate",
  async (payload) => {
    return apiInstance.put(
      `webReviews/webReviewsStatusUpdate?webReviewsId=${payload}`
    );
  }
);

const webReviewsSlice = createSlice({
  name: "webReviewsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // webReviewsGet
    builder.addCase(webReviewsGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(webReviewsGet.fulfilled, (state, action) => {
      state.webReviews = action.payload.webReviews;
      state.isSkeleton = false;
    });
    builder.addCase(webReviewsGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webReviewsCreate
    builder.addCase(webReviewsAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webReviewsAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webReviews.unshift(action.payload.webReviews);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webReviewsAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // webReviewsUpdate
    builder.addCase(webReviewsUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webReviewsUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webReviewsIdx = state.webReviews.findIndex(
          (webReviews) => webReviews._id === action.payload.webReviews._id
        );
        if (webReviewsIdx !== -1) {
          state.webReviews[webReviewsIdx] = {
            ...state.webReviews[webReviewsIdx],
            ...action.payload.webReviews,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webReviewsUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });
    // webReviewsStatusUpdate
    builder.addCase(webReviewsStatusUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webReviewsStatusUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webReviewsIdx = state.webReviews.findIndex(
          (webReviews) => webReviews._id === action.payload.webReviews._id
        );
        if (webReviewsIdx !== -1) {
          state.webReviews[webReviewsIdx] = {
            ...state.webReviews[webReviewsIdx],
            ...action.payload.webReviews,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webReviewsStatusUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // webReviewsDelete
    builder.addCase(webReviewsDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webReviewsDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webReviews = state.webReviews.filter(
          (webReviews) => webReviews._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webReviewsDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webReviewsSlice.reducer;
